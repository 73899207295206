import axios from 'axios';
// import cookie from 'react-cookies';
import * as URL from '../utils/commonConstants';

class ApiService {
    constructor() {
        if (!ApiService.instance) {
            ApiService.instance = this;
        }
        return ApiService.instance;
    }

    api(params, apiName, method, headerData, ipAdd) {
        //let tokenFromCookie = cookie.load('token');
        let header = {};
        // if (tokenFromCookie)
        //     header = { "token": tokenFromCookie.token.accessToken };
        if (headerData)
            Object.keys(headerData).map(ele => {
                header[ele] = headerData[ele];
            })

        header["client-secret"] = "e25hbWU6WmVuaXRoLEVudjpUZXN0aW5nfQ==";

        let url = URL.URL_LIVE;
        if (ipAdd === "imagesUploads") {
            url = URL.URL_IMAGE;
        }
        if (ipAdd === "forex") {
            url = URL.URL_FOREX;
            header = {};
        }
        if(ipAdd ==="b2bhostip"){
            url = URL.b2burl;
            // header = {};
        }
        /** POST request */
        if (method == "POST") {
            return axios.post(url + apiName, params, {
                headers: header,
                withCredentials: false
            });
        }

        /** PUT request */
        if (method == "PUT") {
            return axios.put(url + apiName, params, { headers: header });
        }

        /** GET request */
        if (method == "GET") {
            url = url + apiName;
            if (apiName.includes("?"))
                url = url + "&" + (new Date()).getTime();
            else
                url = url + "?" + (new Date()).getTime();
            return axios.get(url, { headers: header });
        }

       

        /** DELETE request */
        if (method == "DELETE") {
            return axios.delete(url + apiName, { headers: header, data: params })
        }
    }
}
const instance = new ApiService();
Object.freeze(instance);
export default instance;