import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import rootReducer from '../reducers/reducers';
import promiseMiddleware from 'redux-promise-middleware';

export const history = createHistory();

const initialState = {}
const enhancers = []
const middleware = [
    thunk
]

if (process.env.NODE_ENV) {
    if (process.env.NODE_ENV === 'development') {
        const devToolsExtension = window.devToolsExtension;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension())
        }
    }
}

const composedEnhancers = compose(
    ...enhancers,
    applyMiddleware(...middleware, promiseMiddleware)
)

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
)

export default store;