let URL_LIVE_TEMP = "", hotelLogin = {};
var b2bApiHostName = "";
// console.log("window.location.hostname  " + window.location.hostname)
if (window.location.hostname === 'localhost') {
    URL_LIVE_TEMP = "http://35.200.206.198:3002/api/";//"https://stageapi.zenithholidays.com/api/";//
    b2bApiHostName = "https://b2bapi.zenithholidays.com/api/";
    hotelLogin = {
        "CCode": 27,
        "UserName": "apiorchidtest",
        "Password": "apiorchidtest1"
    }
} else if (window.location.hostname === "admin.zenithholidays.com") {
    URL_LIVE_TEMP = "https://store.zenithholidays.com/api/";
    b2bApiHostName = "https://b2bapi.zenithholidays.com/api/";
    hotelLogin = {
        "CCode": 27,
        "UserName": "OrchidGlobal",
        "Password": "@rc#1Xml"
    }
} else if (window.location.hostname === "dev.zenithholidays.com") {
    URL_LIVE_TEMP = "https://stageapi.zenithholidays.com/api/";//"https://stageapi.zenithholidays.com/api/";//
    b2bApiHostName = "https://b2bapi.zenithholidays.com/api/";
    hotelLogin = {
        "CCode": 27,
        "UserName": "apiorchidtest",
        "Password": "apiorchidtest1"
    }
}

export const b2burl = b2bApiHostName;

export const URL_FOREX = "https://www.zenithholidays.com/blog/";

export const VISA_TYPE_ARR = ["Work", "Business", "Tourist", "Transit", "Student", "Visit", "Study", "Attestation", "Dependent", "Seamen", "Work Permit Stamping", "Intra Company Transfer"];

export const HOTEL_LOGIN_CREDENTIAL = hotelLogin;
export const URL_LIVE = URL_LIVE_TEMP;

export const URL_IMAGE = "https://static.zenithholidays.com/api/";
export const IMG_SHOW_URL = "https://static.zenithholidays.com/";

export const ALERT_SUCCESS_MESSAGE_TYPE = "Success";
export const ERROR_TEXT = "error_text";
export const ALERT_ERROR_MESSAGE_TYPE = "Error"
export const pageSizeOptions = [10, 20, 30, 40, 50];
export const imageAccept = "image/jpeg,image/png";
/*******URL DEFINE******** */

export const IMAGE_UPLOAD_URL = "media/upload";
export const MASTER_DATA = "masterData";
export const ADD_PCKAGES_URL = "adminPackage";
export const PACKAGE_LIST_URL = "adminListPackage";
export const TRANSPORT_LIST_URL = "adminListTransport";
export const ENQUIRY_LIST_URL = "adminListEnquiry/";
export const PRICING_LIST_URL = "adminPackageCost";
export const USER_LIST_URL = "adminListRegister";
export const URL_ACTIVE_INACTIVE = "adminPackagePutOnline";
export const URL_ADMIN_DASHBOARD = "adminDashboard";
export const URL_FLIGHT_BOOKING_LIST = "adminGetAllBooking/";
export const URL_CANCEL_ALLFLIGHT_LIST = "allFlightCancelled";
export const URL_ALL_TRANSACTION = "adminTransactions/";
export const URL_ALL_HOTEL_ORDERS = "allHotelOrders/";
export const URL_UPDATE_HOTEL_BOOKING = "hotelUpdateBooking";
export const URL_CANCEL_UPDATE_HOTEL_ORDER = "cancelUpdateHotelOrder/";
export const URL_CANCEL_HOTEL_BOOKING = "hotelCancelBooking";
export const URL_CANCEL_ALLHOTEL_LIST = "allHotelCancelled";
export const URL_ALL_EXCLUSIONS = "allExclusions";
export const URL_ALL_INCLUSIONS = "allInclusions";
export const URL_STATUS_UPDATE_INCLUSIONS = "statusUpdateInclusion/";
export const URL_STATUS_UPDATE_EXCLUSIONS = "statusUpdateExclusion/";
export const URL_UPDATE_EXCLUSIONS = "updateExclusion/";
export const URL_UPDATE_INCLUSIONS = "updateInclusion/";
export const URL_ADD_EXCLUSIONS = "addExclusion";
export const URL_ADD_INCLUSIONS = "addInclusion";
export const URL_JOB_LIST = "adminJobList";
export const URL_JOB_POST = "jobPost";
export const URL_JOB_STATUS_UPDATE = "jobStatusPut/";
export const URL_JOB_DETAIL = "getJobAdmin/";
export const URL_JOB_UPDATE = "jobPut/";
export const URL_JOB_MASTER_DATA = "adminJobMasterList/";
export const URL_JOB_RESUME_LIST = "adminResumeList";
export const URL_UPDATE_JOBDATA_IN_LIST = "jobMasterPut/";
export const URL_DEPARTEMENT_LOCATION_ADD = "jobMasterPost";
export const URL_UPDATESTATUS_DEPARTMENT_LOCATION = "jobMasterStatusPut/";
export const URL_VOUCHER_LIST = "adminVoucherList/";
export const URL_VOUCHER_STATUS_UPDATE = "voucherStatusPut/";
export const URL_VOUCHER_DETAILS = "adminGetVoucher/";
export const URL_VOUCHER_DETAILS_UPDATE = "voucherPut/";
export const URL_VOUCHER_DETAILS_ADD = "voucherPost";
export const URL_PACKAGE_COPY = "duplicatePackage/";
export const URL_CSV_DOWNLOAD = "csvDownload/";
export const URL_VISA_LIST = "adminVisaLists";
export const URL_ADD_VISA = "visaPost";
export const URL_UPDATE_VISA = "visaPut/";
export const URL_VISA_DETAILS = "visaAdminGet/";
export const URL_VISA_ISACTIVE_STATUS = "visaStatusPut/";
export const URL_VISA_ORDERS_LIST = "adminVisaOrders";
export const URL_PACKAGE_ORDERS_LIST = "adminPackageOrders";
export const URL_GET_ALL_COUNTRY_FLIGHT = "getAirportCountries";
export const URL_VISA_TRACKING_STATUS = "visaTrackingStatus/";
export const URL_VOUCHER_DEFAULT_SHOW = "voucherDefaultPut/";
export const URL_FOREX_ACC_LIST = "icici-forex";
export const URL_FOREX_STAGE_ACC_LIST = "icici-forex";//"icici-forex-stage";

export const URL_FRANCHIES_BALANCE = "getAdminBalance";
export const URL_FRANCHIES_BALANCE_CREDITED = "getCreditedStatus";
export const URL_FRAN_BALA_APPROVE_STATUS = "walletPutStatus";
export const URL_FRAN_REGISTRATION = "register";
export const URL_PARTNER_ACTIVE_INACTIVE="partnerStatus/"

let superAdmin = ["/", "/dashboard", "/customers", "/packages", "/packages/list", "/packages/orderlist", "/editPackage", "/addPackage", "/activepackages", "/editactivepackage", "/addactivepackage",
    "/transport", "/enquiry", "/enquiry/E", "/enquiry/V", "/enquiry/M", "/enquiry/C", "/transaction", "/careers", "/careers/jobs", "/careers/resumes",
    "/careers/jobpost", "/flight", "/flight/fbooking", "/flight/cancelflights", "/hotels", "/hotels/hbooking", "/hotels/cancelhotels", "/master", "/master/inclusion", "/master/exclusion",
    "/master/jobdepartment", "/master/joblocation", "/giftvoucher", "/voucher", "/visa", "/visa/vlist", "/visa/vadd", "/visa/vedit", "/visa/orderlist", "/forex", "/forex/accounts", 
    "/forex/transactionhistory", "/statements", "/franchies", "/franchies/balance", "/franchies/approvebalance","/franchies/partnerlist","/franchies/addpartner"];
let admin = ["/dashboard", "/packages", "/packages/list", "/packages/orderlist", "/editPackage", "/addPackage", "/master", "/master/inclusion", "/master/exclusion"];
let subadmin = ["/dashboard", "/packages", "/packages/list", "/packages/orderlist", "/editPackage", "/addPackage"];
let hrAdmin = ["/dashboard", "/careers", "/careers/jobs", "/careers/resumes", "/careers/jobpost", "/master", "/master/jobdepartment", "/master/joblocation"];
let visaAdmin = ["/dashboard", "/enquiry", "/enquiry/V", "/visa", "/visa/vlist", "/visa/vadd", "/visa/vedit", "/visa/orderlist"];
let giftAdmin = ["/dashboard", "/packages", "/packages/list", "/packages/orderlist", "/editPackage", "/addPackage", "/master", "/master/inclusion", "/master/exclusion", "/giftvoucher", "/voucher"];
let acAdmin = ["/dashboard", "/transaction", "/flight", "/flight/fbooking", "/hotels", "/hotels/hbooking"];
let forexAdmin = ["/forex", "/forex/accounts", "/forex/transactionhistory", "/statements"];

export const loginIds = [
    { email: "munesh.kumar@zenithholidays.com", pass: "#1234munesh", type: "superadmin", path: superAdmin, privilege: [] },
    { email: "karan.chadha@zenithholidays.com", pass: "#karan123", type: "superadmin", path: superAdmin, privilege: [] },
    { email: "paras@zenithholidays.com", pass: "#paras123", type: "superadmin", path: superAdmin, privilege: [] },
    { email: "abhishek.anupam@zenithholidays.com", pass: "#1234anupam", type: "superadmin", path: superAdmin, privilege: [] },
    { email: "anand.kumar@zenithholidays.com", pass: "@#1234anand", type: "superadmin", path: superAdmin, privilege: [] },
    { email: "uttam.chandra@zenithholidays.com", pass: "@#uttam12", type: "admin", path: admin, privilege: [] },
    { email: "online.mis@zenithhospitality.net", pass: "@!online123", type: "giftAdmin", path: giftAdmin, privilege: [] },
    { email: "int.product@zenithholidays.com", pass: "@product#123", type: "admin", path: admin, privilege: [] },
    { email: "leisure.ccu@zenithholidays.com", pass: "sayantaN@!#123", type: "subadmin", path: subadmin, privilege: [] },
    { email: "sandeep.dubey@zenithholidays.com", pass: "sandeep#_123", type: "visaAdmin", path: visaAdmin, privilege: [] },
    { email: "hrd3@zenithholidays.com", pass: "hrd@#1234_", type: "hrAdmin", path: hrAdmin, privilege: [] },
    { email: "accounts.cp@zenithholidays.com", pass: "Prakash@ac#$1234", type: "acAdmin", path: acAdmin, privilege: [] },
    { email: "mahakant.jha@zenithholidays.com", pass: "Mahakant#ac_123&", type: "acAdmin", path: acAdmin, privilege: [] },
    { email: "shilpi.gautam@zenithholidays.com", pass: "&#shilpi_1234", type: "superAdmin", path: superAdmin, privilege: [] },
    { email: "finance.northwest@zenithforex.com", pass: "Shalendra@fx_245", type: "forexAdmin", path: forexAdmin, privilege: [] },
    { email: "accounts.forex@zenithholidays.com", pass: "Ravindra#_123*fx", type: "forexAdmin", path: forexAdmin, privilege: [] },
]; 
