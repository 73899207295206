import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import './App.scss';
import { history } from './redux/store/index';

import {
  commonGateway, commonGatewayRequestDone
} from '../src/redux/actions';

//custom routes
import { PrivateRoute } from '../src/utils/PrivateRoutes';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

// const Register = Loadable({
//   loader: () => import('./views/Pages/Register'),
//   loading
// });

// const Page404 = Loadable({
//   loader: () => import('./views/Pages/Page404'),
//   loading
// });

// const Page500 = Loadable({
//   loader: () => import('./views/Pages/Page500'),
//   loading
// });

class App extends Component {
  componentWillMount() {
    console.log(localStorage.getItem('token'))
    
    if (localStorage.getItem('token') && localStorage.getItem('token') !== "") {
      setTimeout(() => {
        localStorage.setItem('token', "");
      }, 12 * 60 * 60 * 1000)
      //history.push("/dashboard")
    } else {
      history.push("/login")
    }
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          {/* <Route exact path="/register" name="Register Page" component={Register} /> */}
          {/* <Route exact path="/404" name="Page 404" component={Page404} /> */}
          {/* <Route exact path="/500" name="Page 500" component={Page500} /> */}
          {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
          <PrivateRoute path='/' name="Home" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}


const mapDispatchToProps = (dispatch) => bindActionCreators({
  commonGateway, commonGatewayRequestDone
}, dispatch)

App = connect(null, mapDispatchToProps)(App);

export default App;
