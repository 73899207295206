import * as constants from '../constants';
import * as URL from '../../utils/commonConstants';

const initialState = {}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        /** For common gateway multiple request at a time  */
        case constants.COMMON_GATEWAY_MULTIPLE + "_PENDING":
            {
                return {
                    ...state,
                    fetching: true
                }
            }
        case constants.COMMON_GATEWAY_MULTIPLE + "_REJECTED":
            {
                let stateToReturn = {
                    ...state,
                    fetching: false,
                }
                stateToReturn[URL.ERROR_TEXT] = action.payload.response;
                return stateToReturn;
            }
        case constants.COMMON_GATEWAY_MULTIPLE + "_FULFILLED":
            {
                let stateToReturn = {
                    ...state,
                    fetched: true
                }
                stateToReturn[action.meta.reducerKey] = action.payload;
                return stateToReturn;
            }

        /** For common gateway  */
        case constants.COMMON_GATEWAY + "_PENDING":
            {
                return {
                    ...state,
                    fetching: true
                }
            }
        case constants.COMMON_GATEWAY + "_REJECTED":
            {
                let stateToReturn = {
                    ...state,
                    fetching: false,
                }
                stateToReturn[URL.ERROR_TEXT] = action.payload.response;
                return stateToReturn;
            }
        case constants.COMMON_GATEWAY + "_FULFILLED":
            {
                let stateToReturn = {
                    ...state,
                    fetched: true
                }
                stateToReturn[action.meta.reducerKey] = action.payload.data;
                return stateToReturn;
            }

        /** Used to set value of reducerKey to undefined. */
        case constants.COMMON_GATEWAY_REQUEST_DONE:
            {
                let stateToReturn = { ...state }
                stateToReturn[action.reducerKey] = undefined;
                return stateToReturn;
            }

        default:
            return state
    }
}