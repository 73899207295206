import axios from 'axios';
import * as constants from '../constants';
import XHR from "../../services/xhr"

/** Common Gateway for Multiple API hits at a time */
export function commonGatewayMultiple(param) {
    const { content, reducerKey } = param;
    return (dispatch, getState) => {
        dispatch({
            type: constants.COMMON_GATEWAY_MULTIPLE,
            meta: { "reducerKey": reducerKey },
            payload: axios.all(content)
        })
    }
}

/** Common Gateway for API hit */
export function commonGateway(param) {
    return (dispatch, getState) => {
        const { url, method, resource, reducerKey, reqData, header } = param;
        dispatch({
            type: constants.COMMON_GATEWAY,
            meta: { "reducerKey": reducerKey },
            payload: XHR.api(reqData, url, method, header, resource)
        })
    }
}

/** Common Gateway request Done */
export function commonGatewayRequestDone(param) {
    const { reducerKey } = param;
    return (dispatch, getState) => {
        dispatch({
            type: constants.COMMON_GATEWAY_REQUEST_DONE,
            "reducerKey": reducerKey
        })
    }
}
