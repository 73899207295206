import { combineReducers } from 'redux';
import { commonReducer } from './common-reducer';
import { reducer as reduxFormReducer } from 'redux-form';
import { alertReducer } from '../../utils/alert/reducer/alert-reducer';
import { loaderReducer } from '../../utils/loader/reducer/loader-reducer';

const integratedReducer = combineReducers({
    commonData: commonReducer,
    form: reduxFormReducer,
    loader: loaderReducer,
    alertState: alertReducer,
});
export default integratedReducer;