import React, { Component } from 'react';
import {
  Route,
  Redirect,
  withRouter
} from 'react-router-dom';
//import cookie from 'react-cookies';

export const PrivateRoute = ({ ...rest }) => {
  let path = { token: localStorage.getItem('token') && localStorage.getItem('token') != "" ? true : false }
  return <Route {...rest} render={(props) => {
    return path.token && path.token.length > 0
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }} />
  }} />
}